import { cloneDeep, includes } from 'lodash';

import { isGuidanceObject, withGuidanceV2 } from 'common/core/approvals/index_new';
import { View } from 'common/types/view';

import Environment from 'StorytellerEnvironment';
import { Block, StorySerialized, StoryViewRights } from 'types';

/**
 * Checks to see if the story you are viewing is published
 * @returns boolean
 */
export const isStoryPublished = () => {
  // Checks to see if the story is a published version
  return Environment.IS_STORY_PUBLISHED;
};

/**
 * Checks to see if the user can publish the current story
 * @returns boolean
 */
export const canPublishCurrentStory = (UserAuthorization?: { viewRights: StoryViewRights }) => {
  const isOwner = includes(UserAuthorization?.viewRights, 'grant');
  return isOwner;
};

/**
 * Determine if the current story will enter an approval queue
 * @returns boolean
 */
export const storyWillEnterApprovalsQueue = (): boolean => {
  return !!(
    isGuidanceObject(Environment.APPROVALS_GUIDANCE) &&
    withGuidanceV2(Environment.APPROVALS_GUIDANCE).willEnterApprovalQueue()
  );
};

/**
 * Generates the correct url for copying a story
 * This url is different depending on if you are copying a published
 * or draft story
 * @returns String
 */
export const getCopyAPIUrl = () => {
  const action = isStoryPublished() ? 'copy_published' : 'copy';
  return `/stories/s/${Environment.STORY_UID}/${action}`;
};

/**
 * Check if the type of component is movable in the story
 * @param componentType
 * @returns boolean
 */
export const isComponentTypeMovable = (componentType: string): boolean => {
  return includes(
    [
      'assetSelector',
      'embeddedHtml',
      'image',
      'measure.card',
      'measure.chart',
      'socrata.visualization.barChart',
      'socrata.visualization.choroplethMap', // legacy
      'socrata.visualization.classic',
      'socrata.visualization.calendar',
      'socrata.visualization.columnChart',
      'socrata.visualization.comboChart',
      'socrata.visualization.scatterChart',
      'socrata.visualization.featureMap',
      'socrata.visualization.histogram',
      'socrata.visualization.map',
      'socrata.visualization.pieChart',
      'socrata.visualization.regionMap',
      'socrata.visualization.table',
      'socrata.visualization.agTable',
      'socrata.visualization.timelineChart',
      'socrata.visualization.vizCanvas',
      'story.tile',
      'story.widget',
      'youtube.video'
    ],
    componentType
  );
};

export const serializeStoryFromEnvironment = (storyData: StorySerialized) => {
  const serializedStory = {
    uid: storyData.uid,
    title: storyData.title,
    description: storyData.description,
    dataSource: storyData.dataSource ? storyData.dataSource : undefined,
    theme: storyData.theme,
    layout: storyData.layout,
    blocks: storyData.blocks.map((block: Block) => ({
      layout: block.layout,
      components: cloneDeep(block.components),
      presentable: block.presentable,
      background_color: block.background_color
    }))
  };
  return serializedStory;
};

export const setChatbotContext = (key: string, viewData: View) => {
  if (!window.initialState) {
    window.initialState = {};
  }
  (window.initialState as Record<string, View>)[key] = viewData;
};
