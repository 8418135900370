import type { Layout } from 'react-grid-layout';
import { isEqual } from 'lodash';
import { getDefaultLayout } from './util';
import { ComponentType } from '../../types';
class BlockItemModel {
  public type: ComponentType;
  /**
   * The index of the BlockItem in the StoreStore
   */
  public index: number;
  public blockId: string;
  public id: string;
  public value: any;
  private _layout: Layout;

  constructor(layout: Layout, type: ComponentType, value: any, index: number, blockId: string) {
    this.index = index;
    this.type = type;
    this.value = value;
    this.id = generateComponentId(blockId, index);
    this._layout = this._updateLayoutWithDefaults(layout, type, this.id);
  }

  /**
   * Adds default min and max values to the layout depending on component type.
   * @param originalBlockItem - Block item without default layout
   * @param BlockItemType - The ComponentType
   * @returns react grid layout
   **/
  private _setDefaultMinMaxLayout(originalLayout: Layout, BlockItemType: ComponentType): Layout {
    const defaultBlockItemLayout = getDefaultLayout(BlockItemType);

    const updatedLayout: Layout = {
      ...originalLayout,
      maxW: defaultBlockItemLayout.maxW,
      maxH: defaultBlockItemLayout.maxH,
      minW: defaultBlockItemLayout.minW,
      minH: defaultBlockItemLayout.minH
    };

    return updatedLayout;
  }

  /**
   * Changes the width and height to fit within the max and min boundaries
   * @param originalBlockItem - Block item without default layout
   * @param BlockItemType - The ComponentType
   * @returns react grid layout
   **/
  private _containLayoutWidthHeight(originalLayout: Layout, BlockItemType: ComponentType): Layout {
    const defaultBlockItemLayout = getDefaultLayout(BlockItemType);

    let containedWidth: number | undefined = originalLayout.w,
      containedHeight: number | undefined = originalLayout.h;

    // Handle setting the width
    if (defaultBlockItemLayout.minW !== undefined) {
      containedWidth = Math.max(containedWidth, defaultBlockItemLayout.minW);
    }
    if (defaultBlockItemLayout.maxW !== undefined) {
      containedWidth = Math.min(containedWidth, defaultBlockItemLayout.maxW);
    }

    // Handle setting the height
    if (defaultBlockItemLayout.minH !== undefined) {
      containedHeight = Math.max(containedHeight, defaultBlockItemLayout.minH);
    }
    if (defaultBlockItemLayout.maxH !== undefined) {
      containedHeight = Math.min(containedWidth, defaultBlockItemLayout.maxH);
    }

    const updatedLayout: Layout = {
      ...originalLayout,
      w: containedWidth,
      h: containedHeight
    };

    return updatedLayout;
  }

  /**
   * Adds any missing layout information with default layout information.
   * This will not overwrite anything provided in the original layout.
   * @param originalBlockItem - Block item without default layout
   * @param BlockItemType - The ComponentType
   * @returns react grid layout
   **/
  private _addMissingLayoutDefaults(originalLayout: Layout, BlockItemType: ComponentType): Layout {
    const defaultBlockItemLayout = getDefaultLayout(BlockItemType);
    const updatedLayout: Layout = {
      ...defaultBlockItemLayout,
      ...originalLayout,
      // React grid layout defines these, so we need to add them to our own layout objects.
      // Otherwise, _.isEqual will fail, thinking the layouts are different when they're not.
      isBounded: undefined,
      isDraggable: undefined,
      isResizable: undefined,
      resizeHandles: undefined,
      static: false,
      moved: false
    };

    return updatedLayout;
  }

  private _setLayoutId(originalLayout: Layout, id: string): Layout {
    const updatedLayout = { ...originalLayout };
    updatedLayout.i = id;

    return updatedLayout;
  }

  private _updateLayoutWithDefaults(
    layout: Layout,
    blockItemType: ComponentType,
    blockItemId: string
  ): Layout {
    let newLayout = this._addMissingLayoutDefaults(layout, blockItemType);
    newLayout = this._setDefaultMinMaxLayout(newLayout, blockItemType);
    newLayout = this._containLayoutWidthHeight(newLayout, blockItemType);
    newLayout = this._setLayoutId(newLayout, blockItemId);
    return newLayout;
  }

  /**
   *
   * @param { ComponentType } type - Component Type
   * @returns { boolean } - True if the the type was changed, false if it was the same type.
   */
  public updateComponentType(type: ComponentType): boolean {
    if (this.type !== type) {
      this.type = type;
      this._layout = this._updateLayoutWithDefaults(this._layout, type, this.id);
      return true;
    }

    return false;
  }

  public hasLayoutChanged(newLayout: Layout): boolean {
    return !isEqual(this._layout, newLayout);
  }

  public set layout(newLayout: Layout) {
    this._layout = { ...newLayout };
  }

  public get layout(): Layout {
    return this._layout;
  }
}

/**
 * Creates an id based off the blockId and blockItem index.
 * @param {string} blockId - The parent block id
 * @param {number} index - The index of the BlockItem in the StoryStore
 * @returns {String} A unique id for a component container within the current story. Not unique over time.
 */
export const generateComponentId = (blockId: string, index: number): string => {
  return `${blockId}-block-item-index-${index}`;
};

/**
 * Creates an id meant to be used as the react key for an individual component.
 * When the type changes (assetSelector becomes viz), React will not reuse the assetSelector,
 * and useStates/useRefs will be reset, which makes a lot more intuitive sense.
 * @param {string} blockId - The parent block id
 * @param {number} index - The index of the BlockItem in the StoryStore
 * @param {string} type - The type of the component
 * @returns {String} A unique id for a component/type within the current story. Not unique over time.
 */
export const generateReactKey = (blockId: string, index: number, type: string): string => {
  return `${blockId}_${index}_${type}`;
};

export default BlockItemModel;
