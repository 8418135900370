import { isEqual, get } from 'lodash';
import React, { FC, memo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Environment from 'StorytellerEnvironment';
import StorytellerUtils from 'lib/StorytellerUtils';
import Actions from 'Actions';
import { dispatcher } from 'Dispatcher';
import { storyStore } from 'editor/stores/StoryStore';
import FeatureFlags from 'common/feature_flags';

import type { Vif as LatestVif, V1TableVif } from 'common/visualizations/vif';
import Visualization from 'common/visualizations/components/Visualization';

import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { updateVifWithDefaults, getDefaultVisualizationProps, vifsAreEquivalent } from 'lib/VifUtils';
import {
  applyAdditionalFiltersToVif,
  applyParameterOverridesToVif
} from 'common/visualizations/helpers/VifHelpers';
import { VisualizationOptions } from 'common/visualizations/views/BaseVisualization/types';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import { filtersAreEquivalent } from 'lib/FilterUtils';

type PreMigrationVif = LatestVif | V1TableVif;

interface StoryVisualizationProps {
  storyVif: PreMigrationVif;
  options?: VisualizationOptions;
}

const defaultOptions = getDefaultVisualizationProps();
const enablePersonalization = !Environment.EDIT_MODE && FeatureFlags.value('enable_saved_state_in_stories');

// Note: You probably should NOT use this kind of hack in your own components.
// This is helpful when working with jQuery components that don't play nice with React.
const useForceRerender = () => {
  const [, setTick] = useState(0);
  return () => setTick((tick) => tick + 1);
};

// Because this component is called with ReactDOM.render, there's no way to stop a re-render even
// if the props are the same. Wrapping in `memo` lets us switch to isEqual.
// See: https://react.dev/reference/react/memo
const StoryVisualization: FC<StoryVisualizationProps> = memo(
  ({ storyVif, options }: StoryVisualizationProps) => {
    const additionalFilters = useSelector(dataSourceSelectors.getGlobalFilters, filtersAreEquivalent);
    const parameterOverrides = useSelector(dataSourceSelectors.getParameterOverrides, isEqual);

    const prepareVifForRender = (vif: PreMigrationVif) => {
      const migratedVif = migrateVif(vif);
      const defaultedVif = updateVifWithDefaults(migratedVif);

      // vifToRender has the ephemeral state, but we need the non-ephemeral state for filters.
      const migratedStoryVif = migrateVif(storyVif);
      const origFilters = get(migratedStoryVif, 'series[0].dataSource.filters', []);
      const vifWithFilters = applyAdditionalFiltersToVif(defaultedVif, additionalFilters, origFilters);

      return applyParameterOverridesToVif(vifWithFilters, parameterOverrides);
    };

    // If we're at this point, we know for sure that at least one of
    // storyVif, additionalFilters, or parameterOverrides has changed,
    // or we forced a re-render because of an internal viz update.

    const vifToRender = useRef<PreMigrationVif | null>(null);
    const prevStoryVif = useRef<PreMigrationVif | null>(null);
    const forceRerender = useForceRerender();
    if (!vifToRender.current || !prevStoryVif.current || !vifsAreEquivalent(prevStoryVif.current, storyVif)) {
      // Either the first render, or we re-inserted the viz from AX.
      vifToRender.current = prepareVifForRender(storyVif);
      prevStoryVif.current = storyVif;
    } else {
      // This means either additionalFilters or parameterOverrides have changed.
      vifToRender.current = prepareVifForRender(vifToRender.current);
    }

    /**
     * This is how we preserve ephemeral state for vizes, while still merging in filter changes.
     * There's some complexity to this feature, so I'll be unnecessarily verbose.
     * All viz user interactions will emit events, but only some use `emitVifEvent`.
     * Those will bubble up to BaseVisualization, which checks `handleVifUpdatesInternally`.
     * If true, it will tell itself to rerender with `SOCRATA_VISUALIZATION_RENDER_VIF`.
     * If not, it emits `SOCRATA_VISUALIZATION_VIF_UPDATED` and expects the parent to handle it.
     * The parent is normally AX or VizCan, but now it's us, which is why we forceRender.
     * This is mainly for the QFB. If we don't re-render, the viz won't get the new filter.
     */

    // WET: copied this way of getting the type from VisualizationRenderer
    const onVifUpdated = (event: any) => {
      const newVif = event.detail;
      vifToRender.current = prepareVifForRender(newVif);

      if (enablePersonalization && Environment.STORY_UID) {
        // TODO: Cleanup with EN-73613. Currently we use jQuery here to access the closest components from Story Store, we'd like to move away from jQuery and use useContext once CBR is removed.
        const blockId = StorytellerUtils.findClosestAttribute($(event.target), 'data-block-id');
        const componentIndex = StorytellerUtils.findClosestAttribute($(event.target), 'data-component-index');

        // Get current component
        const component = storyStore.getBlockComponentAtIndex(blockId, componentIndex);

        dispatcher.dispatch({
          action: Actions.BLOCK_UPDATE_COMPONENT,
          blockId,
          componentIndex,
          type: component.type,
          value: {
            ...component.value,
            vif: newVif
          }
        });
      }
      // If vifToRender was useState, we wouldn't need this.
      // But for this, I think useRef and forceRerender is easier to understand.
      forceRerender();
    };
    // Again, this is false because we'll receive updates here and must re-render.
    defaultOptions.drilldown.handleVifUpdatesInternally = false;

    const optionsWithDefaults = options ? { ...defaultOptions, ...options } : defaultOptions;
    return (
      <Visualization vif={vifToRender.current} options={optionsWithDefaults} onVifUpdated={onVifUpdated} />
    );
  },
  (prevProps, nextProps) => {
    return vifsAreEquivalent(prevProps.storyVif, nextProps.storyVif);
  }
);

export default StoryVisualization;
