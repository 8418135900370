import $ from 'jquery';
import { defaults, extend, includes, isEmpty, isString, once, pick, toUpper, } from 'lodash';

import Environment from 'StorytellerEnvironment';
import { assert, assertIsOneOfTypes } from 'common/assertions';
import { csrfToken } from 'common/http';
import { exceptionNotifier } from './ExceptionNotifier';
import { ensureCsrfToken } from 'common/http';

const SUPPORTED_REQUEST_METHODS = ['GET', 'PUT', 'POST', 'PATCH', 'DELETE'];
const SUCCESS_STATUS_CODES = [200, 201, 204];
const PERMITTED_OPTIONS = ['data', 'dataType', 'contentType', 'headers'];

// Wrapped with _.once to prevent possible Airbrake spam.
const notifyMissingAppToken = once(() => {
  exceptionNotifier.notify(new Error(
    'Environment.CORE_SERVICE_APP_TOKEN not configured.'
  ));
});

export const coreHeaders = () => {
  if (isEmpty(Environment.CORE_SERVICE_APP_TOKEN)) {
    notifyMissingAppToken();
  }

  return {
    'X-App-Token': Environment.CORE_SERVICE_APP_TOKEN,
    'X-CSRF-Token': ensureCsrfToken(),
    'X-Socrata-Host': window.location.hostname
  };
};

export const storytellerHeaders = () => {
  return {
    'X-App-Token': Environment.CORE_SERVICE_APP_TOKEN,
    'X-CSRF-Token': csrfToken(),
    'X-Socrata-Host': window.location.hostname
  };
};

export const federationHeaders = () => ({
  'X-Socrata-Federation': 'Honey Badger'
});

export function httpRequest(method, url, options) {
  // Normalize arguments with reasonable defaults.
  method = toUpper(method);

  options = defaults(options, {
    dataType: 'json',
    contentType: 'application/json',
    headers: {}
  });

  // Prepare JSON data if necessary.
  if (options.data && !isString(options.data) && options.contentType === 'application/json') {
    options.data = JSON.stringify(options.data);
  }

  // Sanity check for required parameters.
  assertIsOneOfTypes(url, 'string');
  assert(
    includes(SUPPORTED_REQUEST_METHODS, method),
    `Unsupported HTTP method ${method}; supported methods: ${SUPPORTED_REQUEST_METHODS.join(', ')}`
  );

  return new Promise((resolve, reject) => {
    // A custom rejection handler that munges httpRequest arguments with
    // jQuery's XHR properties.
    function handleError(jqXHR, xhrStatus) {
      const { status, responseText } = jqXHR;
      const response = JSON.stringify(responseText) || '<No response>';

      if (SUCCESS_STATUS_CODES.includes(status) && xhrStatus === 'parsererror') {
        // Workaround for bad Core behavior - see EN-13538
        resolve({
          data: null,
          status: 'success',
          jqXHR
        });
      } else {
        // Actually an error condition
        const responseError = new Error(
          `Request "${method} ${url}" failed (${status}) ${response}`
        );
        responseError.statusCode = status;
        responseError.response = responseText;
        responseError.jqXHR = jqXHR;

        reject(responseError);
      }
    }

    $.ajax(extend(
      // Only allow specified options to be passed through directly.
      pick(options, PERMITTED_OPTIONS),
      {
        // Required parameters.
        url,
        method,
        // Callbacks and lifecycle settings.
        processData: !(options.data instanceof Blob),
        success: (data, status, jqXHR) => resolve({ data, status, jqXHR }),
        error: handleError
      }
    ));
  });
}

export default httpRequest;
