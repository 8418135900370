import { createReducer, createAction } from '@reduxjs/toolkit';
import PersonalizedStoryCreator from 'view/PersonalizedStoryCreator';

export const PERSONALIZED_STORY_SAVED = 'PERSONALIZED_STORY_SAVED';
export const PERSONALIZED_STORY_LOADED = 'PERSONALIZED_STORY_LOADED';
export const TRIGGER_PERSONALIZED_STORY_RESET = 'TRIGGER_PERSONALIZED_STORY_RESET';
export const PERSONALIZED_STORY_RESET = 'PERSONALIZED_STORY_RESET';
//not currently being used, but if we need to save personalized story separately, we can use it
export const PERSONALIZED_STORY_UPDATE_VIF = 'PERSONALIZED_STORY_UPDATE_VIF';

export const personalizedStorySaved = createAction<{
  storyUid: string;
  story: any;
}>(PERSONALIZED_STORY_SAVED);

export const personalizedStoryLoaded = createAction<{
  storyUid: string;
}>(PERSONALIZED_STORY_LOADED);

export const triggerPersonalizedStoryReset = createAction(TRIGGER_PERSONALIZED_STORY_RESET);

export const personalizedStoryReset = createAction<{
  storyUid: string;
}>(PERSONALIZED_STORY_RESET);

interface PersonalizedStoryState {
  storyUid: string | null;
  story: any | null;
  updatedAt: string | null;
  isPersonalizedVersion: boolean;
}

const initialState: PersonalizedStoryState = {
  storyUid: null,
  story: null,
  updatedAt: null,
  isPersonalizedVersion: false
};

const personalizedStoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(personalizedStorySaved, (state) => {
      state.isPersonalizedVersion = true;
    })
    .addCase(personalizedStoryLoaded, (state, action) => {
      const { storyUid } = action.payload;
      state.storyUid = storyUid;
      state.isPersonalizedVersion = true;
    })
    .addCase(triggerPersonalizedStoryReset, (state) => {
      const storyUid = state.storyUid;
      if (storyUid) {
        PersonalizedStoryCreator.resetPersonalizedStory(storyUid);
      }
    })
    .addCase(personalizedStoryReset, (state) => {
      state.isPersonalizedVersion = false;
    });
});

export default personalizedStoryReducer;
