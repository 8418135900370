import { assert, assertIsOneOfTypes } from 'common/assertions';
import { ApiError } from 'types';
import httpRequest, { storytellerHeaders } from 'services/httpRequest.js';
import { storyStore } from '../editor/stores/StoryStore';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import {
  personalizedStorySaved,
  personalizedStoryReset,
  personalizedStoryLoaded
} from 'store/reducers/PersonalizedStoryReducer';
import { createStory } from 'store/TopLevelActions';
import { populateGlobalFilter } from 'store/reducers/DataSourceReducer';
import Environment from 'StorytellerEnvironment';

function loadPersonalizedStory(storyUid: string) {
  StorytellerReduxStore.dispatch(
    personalizedStoryLoaded({
      storyUid
    })
  );
}

function savePersonalizedStory(storyUid: string) {
  assertIsOneOfTypes(storyUid, 'string');
  assert(storyStore.doesStoryExist(storyUid), 'Cannot save personalized version of non-existent story');

  const originalStoryJson = storyStore.serializeStory(storyUid);

  const headers = {
    ...storytellerHeaders(),
    'Content-Type': 'application/json'
  };

  const requestBody = {
    personalized_story_data: originalStoryJson
  };

  const url = `/stories/api/v1/stories/${storyUid}/personalized`;
  return httpRequest('POST', url, {
    data: requestBody,
    headers: headers
  }).then(
    () => {
      StorytellerReduxStore.dispatch(
        personalizedStorySaved({
          storyUid,
          story: originalStoryJson
        })
      );
      return originalStoryJson;
    },
    (error: ApiError) => {
      console.error('Personalized story save failed:', error);
      return Promise.reject(error);
    }
  );
}

function resetPersonalizedStory(storyUid: string) {
  assertIsOneOfTypes(storyUid, 'string');
  const url = `/stories/api/v1/stories/${storyUid}/personalized`;

  const headers = {
    ...storytellerHeaders(),
    'Content-Type': 'application/json'
  };

  return httpRequest('DELETE', url, {
    headers: headers
  }).then(() => {
    StorytellerReduxStore.dispatch(personalizedStoryReset({ storyUid }));
    // Add null check for STORY_DATA
    if (Environment.STORY_DATA) {
      StorytellerReduxStore.dispatch(createStory(Environment.STORY_DATA));
      StorytellerReduxStore.dispatch(populateGlobalFilter(Environment.STORY_DATA));
    }
  });
}

export default { savePersonalizedStory, resetPersonalizedStory, loadPersonalizedStory };
