import { cloneDeep, isEqual } from 'lodash';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { DataSourceCCVs, DataSourceParameter, ParameterConfiguration } from 'common/types/reportFilters';
import { SpecificValue, SuggestedValuesType } from 'common/types/clientContextVariable';
import FeatureFlags from 'common/feature_flags';

/**
 * Given a list of all the client context variables, add the overrides to each one.
 */
export const mergeClientContextVariables = (
  overrides: ClientContextVariable[],
  all: ClientContextVariable[]
): ClientContextVariable[] => {
  return all.map((variable) => {
    const override = overrides.find(
      ({ name, viewId }) => name === variable.name && viewId === variable.viewId
    );
    if (override?.overrideValue && isOverrideValid(variable, override?.overrideValue)) {
      return { ...variable, overrideValue: override.overrideValue };
    }
    return variable;
  });
};

/**
 * Check the override to see if it is valid. For client context variables with SPECIFIC_VALUES,
 * the override is valid if it is in the suggested values list. For client context variables
 * with ANY_VALUES, all overrides are valid.
 */
const isOverrideValid = (variable: ClientContextVariable, override: string) => {
  const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
    'enable_parameter_dropdowns_in_stories',
    false
  );
  const compareValueOnly = (a: SpecificValue) => {
    return a.value === override;
  };
  if (
    enableParameterDropdownsInStories &&
    variable.suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES &&
    !variable?.suggestedValues?.valueList.find(compareValueOnly)
  ) {
    return false;
  }
  return true;
};

/**
 * Migrate the parameter:
 * 1. If a parameter has no associated client context variable, the user must have deleted it, so remove the
 * parameter or linked parameter.
 * 2. If the client context variable has SPECIFIC_VALUES, then make sure the parameter configuration's
 * override is in the array of suggested values. If it's not in the list, change the override to the
 * default value for the client context variable.
 */
export const migrateParameter = (
  parameterConfig: ParameterConfiguration,
  clientContextVariables: DataSourceCCVs
): ParameterConfiguration | null => {
  const migratedParameterConfig = cloneDeep(parameterConfig);
  // Remove deleted parameters
  migratedParameterConfig.paramIds = migratedParameterConfig.paramIds.filter(
    (paramId: DataSourceParameter) => {
      const dataSourceCCVs = clientContextVariables[paramId.datasetUid];
      return dataSourceCCVs.find((ccv) => ccv.name === paramId.name);
    }
  );
  if (migratedParameterConfig.paramIds.length === 0) {
    return null; //delete the entire parameterConfig
  }

  const dataSourceCCVs = clientContextVariables[migratedParameterConfig.paramIds[0].datasetUid];
  const ccvForParameter = dataSourceCCVs.find((ccv) => ccv.name === migratedParameterConfig.paramIds[0].name);
  if (ccvForParameter) {
    if (!isEqual(parameterConfig.paramIds[0], migratedParameterConfig.paramIds[0])) {
      // The first parameter is different, update the display name
      migratedParameterConfig.displayName = ccvForParameter.displayName ?? ccvForParameter.name;
    }
    if (!isOverrideValid(ccvForParameter, migratedParameterConfig.overrideValue)) {
      // Override value is no longer valid, use defaultValue
      migratedParameterConfig.overrideValue = ccvForParameter.defaultValue;
    }
  }
  return migratedParameterConfig;
};
