import $ from 'jquery';
import { extend } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import I18n from 'common/i18n';
import { assert, assertHasProperty } from 'common/assertions';

import './shared/componentBase';
import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { MeasureProps } from './types';
import { ComponentType, BlockComponent } from 'types';

import ComponentMeasure, { MeasurePropsReact } from './componentMeasure/ComponentMeasure';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

$.fn.componentMeasure = componentMeasure;

export const resizableProps = (componentData: BlockComponent) => {
  return {
    resizeSupported: componentData.type === ComponentType.MEASURE_CHART,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    },
    defaultHeight: Constants.DEFAULT_VISUALIZATION_HEIGHT
  };
};

// This component supports measure.card and measure.chart.
export default function componentMeasure(props: MeasureProps) {
  assertHasProperty(props, 'componentData.type');
  const componentType = props.componentData.type;

  assert(
    componentType === ComponentType.MEASURE_CHART || componentType === ComponentType.MEASURE_CARD,
    `componentMeasure: Unsupported component type ${componentType}`
  );

  props = extend({}, props, resizableProps(props.componentData));

  const $this = $(this);

  if ($this.children().length === 0) {
    renderTemplate($this);
  }

  const $componentContent = $this.find('.component-content');

  const classes = StorytellerUtils.typeToClassesForComponentType(componentType);

  // If the component doesn't currently have the correct classes for the type of measure,
  // it means the user has switched the measure view type and we must update the classes.
  if (!$componentContent.parent().hasClass(classes)) {
    const classesToRemove = StorytellerUtils.typeToClassesForComponentType(
      componentType === ComponentType.MEASURE_CHART ? ComponentType.MEASURE_CARD : ComponentType.MEASURE_CHART
    );
    $componentContent.parent().removeClass(classesToRemove);
    $componentContent.parent().addClass(classes);
  }

  const componentMeasureProps: MeasurePropsReact = {
    ...props,
    jQueryErrorCallback: (error: boolean) => $this.toggleClass('load-error', error)
  };
  ReactDOM.render(
    <Provider store={StorytellerReduxStore}>
      <ComponentMeasure {...componentMeasureProps} />
    </Provider>,
    $componentContent[0]
  );

  if (!shouldUseReactComponentBase()) {
    $this.componentBase(props);
  }

  return $this;
}

function renderTemplate($element: JQuery) {
  const $componentContent = $('<div>', { class: 'component-content' });
  const $componentError = $('<div>', { class: 'component-error' });
  $componentError.text(I18n.t('editor.components.performance_measures.generic_error'));
  $element.append($componentContent).append($componentError);
}
