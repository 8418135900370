import $ from 'jquery';
import _ from 'lodash';

import { assert, assertHasProperties, assertHasProperty, assertInstanceOf } from 'common/assertions';

import './shared/componentBase';
import CustomEvent from 'CustomEvent';
import StorytellerUtils from 'lib/StorytellerUtils';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

/**
 * Creates or updates an image component
 * based on the componentData, theme, and options.
 *
 * @param {object} componentData - Data for image block. See below.
 * @param {string} theme - Theme name. Currently not used.
 * @param {object} options - Renderer settings. Optional. See below.
 *
 *
 * Sample componentData:
 *  {
 *    type: "image",
 *    value: {
 *      documentId: "1234",
 *      url: "https://bucket-name.s3.amazonaws.com/uploads/random/image.jpg",
 *      link: "http://this-image-is-a-link.example.com",
 *      openInNewWindow: false,
 *      crop: {
 *        height: 0,
 *        unit: "%",
 *        width: 0,
 *        x: 0,
 *        y: 0
 *      }
 *    }
 *  }
 *
 * Supported options (default):
 *  - editMode (false): If true, renders an edit button on hover. The edit button
 *    dispatches Actions.ASSET_SELECTOR_EDIT_EXISTING_ASSET_EMBED.
 */
$.fn.componentImage = componentImage;

export default function componentImage(props) {
  const $this = $(this);
  const { componentData, editMode } = props;

  assertHasProperties(componentData, 'type');
  assert(componentData.type === 'image', `componentImage: Unsupported component type ${componentData.type}`);

  if ($this.children().length === 0) {
    _renderImage($this, componentData);
  }

  _updateImageAttributes($this, componentData);

  if (!shouldUseReactComponentBase()) {
    $this.componentBase(props);
  }

  return $this;
}

function _renderImage($element, componentData) {
  assertHasProperty(componentData, 'type');

  const $imgElement = $('<img>', {
    src: null,
    'data-src': null,
    'data-document-id': null,
    'data-crop': null
  });

  // Always open in new tab when editing. Outside of edit mode, image tiles still utilize
  // `_component_image.html.erb` which is where we respect the `value.openInNewWindow` boolean
  const $link = $('<a>', {
    href: null,
    target: '_blank'
  });

  $element
    .addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type))
    .append($link.append($imgElement));
}

function _updateImageAttributes($element, componentData) {
  assertHasProperty(componentData, 'value');
  assertHasProperty(componentData.value, 'url');
  assertHasProperty(componentData.value, 'documentId');

  const $link = $element.find('a');
  const $imgElement = $element.find('img');
  const cropJson = JSON.stringify(_.get(componentData, 'value.crop', {}));
  const imgSrc = componentData.value.url;
  const documentId = componentData.value.documentId; // May be null or undefined.
  const documentIdAsStringOrNull =
    _.isNull(documentId) || _.isUndefined(documentId) ? null : String(documentId);
  const altAttribute = componentData.value.alt;
  const link = componentData.value.link;

  // EN-40244: when updating react-image-crop, we started getting a key, `aspect`, with
  // an undefined value. Calling JSON.stringify() on an object with this key returned a
  // string without `aspect`. The check below to see if the crops were different, then started
  // returning false and we ended up in an infinite loop of reloading the image. So now we are
  // comparing the parsed object from the stringified version of each object.
  if (
    $imgElement[0].getAttribute('data-src') !== imgSrc ||
    $imgElement[0].getAttribute('data-document-id') !== documentIdAsStringOrNull ||
    !_.isEqual(JSON.parse($imgElement[0].getAttribute('data-crop')), JSON.parse(cropJson))
  ) {
    _informHeightChanges($imgElement);
    $imgElement
      .attr('src', imgSrc)
      .attr('data-src', imgSrc)
      .attr('data-document-id', documentId)
      .attr('data-crop', cropJson);
  }

  $link.attr('href', _.isEmpty(link) ? null : link);
  $imgElement.attr('alt', _.isEmpty(altAttribute) ? null : altAttribute);
}

function _informHeightChanges($image) {
  assertInstanceOf($image, $);

  $image.one('load', () => {
    $image[0].dispatchEvent(new CustomEvent('component::height-change', { detail: {}, bubbles: true }));
  });
}
