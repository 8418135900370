import { debounce, isUndefined } from 'lodash';
import { ListenerMiddleware } from '@reduxjs/toolkit';
import Environment from 'StorytellerEnvironment';
import { AUTOSAVE_DEBOUNCE_TIME_IN_MILLISECONDS } from 'lib/Constants';
import PersonalizedStoryCreator from 'view/PersonalizedStoryCreator';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { updateBlockComponent } from 'store/reducers/StoryReducer';
import FeatureFlags from 'common/feature_flags';
import {
  updateFilterParameterConfiguration,
  updateAllFilterParameterConfigurations
} from 'store/TopLevelActions';

// Actions that should trigger personalized story save
const PERSONALIZED_SAVE_ACTIONS = [
  updateBlockComponent?.type,
  updateFilterParameterConfiguration?.type,
  updateAllFilterParameterConfigurations?.type
];

const personalizedStoryMiddleware: ListenerMiddleware = (store) => (next) => (action) => {
  next(action);

  if (!FeatureFlags.value('enable_saved_state_in_stories') || isUndefined(action.type)) return;

  const state = store.getState();

  if (PERSONALIZED_SAVE_ACTIONS.includes(action.type) && !Environment.EDIT_MODE) {
    savePersonalizedOnceSettled(state);
  }
};

let personalizedSaveLock = false;
const personalizedSaveDebounceMsec = AUTOSAVE_DEBOUNCE_TIME_IN_MILLISECONDS;
const savePersonalizedOnceSettled = debounce(
  (state) => savePersonalizedASAP(state as StorytellerState),
  personalizedSaveDebounceMsec
);

const savePersonalizedASAP = (state: StorytellerState) => {
  const storyUid = Environment.STORY_UID;

  if (!storyUid) {
    console.error('No story UID found');
    return;
  }

  if (personalizedSaveLock) {
    savePersonalizedOnceSettled(state);
    return;
  }

  personalizedSaveLock = true;

  PersonalizedStoryCreator.savePersonalizedStory(storyUid)
    .catch((error: any) => {
      console.error('Personalized story save failed:', error);
    })
    .finally(() => {
      personalizedSaveLock = false;
    });
};

export default personalizedStoryMiddleware;
