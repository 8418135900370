import React, { useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { BlockComponent, ComponentType } from 'types';
import BlockEditControls from './BlockSectionEditControls';
import StoryComponentRenderer from './StoryComponentRenderer';
import { generateReactKey } from 'editor/model/BlockItemModel';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';

export interface ClassicBlockSectionProps {
  blockId: string;
  components: BlockComponent[];
  storyUid: string;
  blockIndex: number;
  blockCount: number;
  editMode: boolean;
}

const ClassicBlockSection: React.FC<ClassicBlockSectionProps> = (props) => {
  const { blockId, components, editMode, blockIndex, blockCount, storyUid } = props;

  const backgroundColor = useSelector((state: StorytellerState) =>
    storySelectors.getBlockBackgroundColor(state, blockId)
  );

  const storyLayout = useSelector((state: StorytellerState) =>
    storySelectors.getStoryLayout(state, storyUid)
  );

  const blockLayout = useSelector((state: StorytellerState) => storySelectors.getBlockLayout(state, blockId));
  const componentWidths = blockLayout.split('-');
  const blockRef = useRef(null);
  const isPresentable = useSelector((state: StorytellerState) =>
    storySelectors.isBlockPresentable(state, blockId)
  );

  const getInnerComponentBaseReact = (componentIndex: number, colNum: string) => {
    const reactKey = generateReactKey(blockId, componentIndex, components[componentIndex].type);
    return (
      <StoryComponentRenderer
        key={reactKey}
        blockId={blockId}
        componentIndex={componentIndex}
        additionalClasses={`${colNum} classic-component`}
        editMode={editMode}
      />
    );
  };

  const mapComponents = () => {
    return components.map((_component, componentIndex) => {
      const colNum =
        blockLayout === 'fluid6'
          ? storyLayout === 'narrative'
            ? 'col4'
            : 'col2'
          : `col${componentWidths[componentIndex]}`;
      return getInnerComponentBaseReact(componentIndex, colNum);
    });
  };

  // Special block that renders 6 measure cards in a more space efficient way
  const getFluid6Components = () => {
    return <div className="fluid6">{mapComponents()}</div>;
  };

  // Classic has no way to put a text block right above a media block, so this is a custom layout for that.
  const getTextAndMediaComponents = () => {
    return (
      <>
        <div className="col6 text-top-media">
          {getInnerComponentBaseReact(0, 'col12')}
          {getInnerComponentBaseReact(1, 'col12')}
        </div>
        <div className="col6 text-top-media">
          {getInnerComponentBaseReact(2, 'col12')}
          {getInnerComponentBaseReact(3, 'col12')}
        </div>
      </>
    );
  };

  const getComponents = () => {
    if (blockLayout === '12-12-12-12') {
      return getTextAndMediaComponents();
    } else if (blockLayout === 'fluid6') {
      return getFluid6Components();
    } else {
      return mapComponents();
    }
  };

  const isGlobalFilter = components[0] != null && components[0].type === ComponentType.GLOBAL_FILTER;
  const blockClasses = classNames('block', {
    sticky: isGlobalFilter
  });
  const blockContentClasses = classNames('block-content', {
    'block-edit-outline': editMode
  });

  return (
    <div
      className={blockClasses}
      style={{ backgroundColor }}
      data-block-id={blockId}
      ref={blockRef}
      data-presentable={isPresentable}
    >
      <div className={blockContentClasses}>
        {editMode && (
          <BlockEditControls
            blockId={blockId}
            color={backgroundColor || ''}
            storyUid={storyUid}
            blockIndex={blockIndex}
            blockCount={blockCount}
            parentRef={blockRef}
          />
        )}
        {getComponents()}
      </div>
    </div>
  );
};

export default ClassicBlockSection;
