import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ForgeFloatingActionButton, ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';
import I18n from 'common/i18n';

import { getIsPersonalizedVersion } from 'store/selectors/PersonalizedStorySelectors';
import environment from 'StorytellerEnvironment';
const EmbedPersonalizedResetButton: React.FC = () => {
  const isPersonalized = useSelector(getIsPersonalizedVersion);
  const dispatch = useDispatch();

  const renderPersonalizedResetButton = () => {
    return (
      <ForgeFloatingActionButton mini>
        <button
          type="button"
          aria-label={I18n.t('preview_mode.reset_personalization.label')}
          onClick={resetPersonalizedStory}
        >
          <ForgeIcon name="restore" aria-hidden="true" external external-type="extended"></ForgeIcon>
        </button>
        <ForgeTooltip text={I18n.t('preview_mode.reset_personalization.tooltip')} position="left" />
      </ForgeFloatingActionButton>
    );
  };

  const resetPersonalizedStory = () => {
    dispatch({ type: 'TRIGGER_PERSONALIZED_STORY_RESET' });
  };

  return isPersonalized ? renderPersonalizedResetButton() : null;
};

export default EmbedPersonalizedResetButton;
