/**
 * This files holds functions that were originally written for jQuery based storyteller rendering.
 * They have been extracted to allow React to use the jQuery style without
 * forcing a hard dependency on jQuery in React components.
 *
 * Specifically, many are used by BOTH StoryRenderer (jQuery) and EditStory (React).
 *
 * Some are copied from componentBaseJquery are used only by StoryComponentRenderer/ComponentBaseReact,
 * since we intend to remove componentBaseJquery entirely.
 *
 * The hope is that this paradigm will work well enough, regardless of how
 * long a move to React takes, or how far it goes at all.
 */

import _ from 'lodash';

import StorytellerUtils from 'lib/StorytellerUtils';
import { dispatcher } from 'Dispatcher';
import Actions from 'Actions';
import { storyStore } from '../stores/StoryStore';
import { richTextEditorManager } from '../RichTextEditorManager';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

import type { BlockComponent, RTEContentChangeEvent } from 'types';

export const handleRTEContentChangeEvent = (e: JQuery.TriggeredEvent) => {
  const event = e as RTEContentChangeEvent;
  const blockId = StorytellerUtils.findClosestAttribute(event.target, 'data-block-id');
  const componentIndex = StorytellerUtils.findClosestAttribute(event.target, 'data-component-index');

  const blockContent = event.originalEvent?.detail?.content;
  const editor = event.originalEvent?.detail?.editor;

  const currentBlock = storyStore.getBlockComponentAtIndex(blockId, componentIndex);
  const currentBlockValue = _.get(currentBlock, 'value');
  const newLayout = _.cloneDeep(currentBlock.layout);
  _.set(newLayout, 'h', event.originalEvent?.detail?.layoutHeight);

  if (editor.contentDiffersFrom(currentBlockValue)) {
    dispatcher.dispatch({
      action: Actions.BLOCK_UPDATE_COMPONENT,
      blockId: blockId,
      layout: newLayout,
      componentIndex: componentIndex,
      type: _.get(currentBlock, 'type') || 'html',
      value: blockContent
    });
  }
};

// NOTE: This comment pulled from the original StoryRenderer code.
// Update the toolbar's formats, but only once things have settled down.
// Also, since sometimes this event is triggered by action handlers (updating
// Squire's contents, etc), we need to make sure we defer, as otherwise the Flux
// dispatcher will complain that we're attempting to dispatch within a dispatch.
// debounce() will always at least defer.
const deferredAndDebouncedFormatChangeHandler = _.debounce((content) => {
  dispatcher.dispatch({
    action: Actions.RTE_TOOLBAR_UPDATE_ACTIVE_FORMATS,
    activeFormats: content
  });
});

type RTEFormatChangeEvent = {
  originalEvent: {
    detail: {
      content: any;
    };
  };
} & JQuery.TriggeredEvent;

export const handleRTEFormatChangeEvent = (e: JQuery.TriggeredEvent) => {
  const event = e as RTEFormatChangeEvent;
  deferredAndDebouncedFormatChangeHandler(event.originalEvent?.detail?.content);
};

export const attachAssetSelectorButtons = ($blockElement: JQuery<HTMLElement>): void => {
  $blockElement.on('click', '[data-action]', function handleClick() {
    if ($(document.body).hasClass('action-overlay-active')) {
      return;
    }
    const action = this.getAttribute('data-action');

    switch (action) {
      case Actions.ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT:
        dispatcher.dispatch({
          action: Actions.ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT,
          blockId: StorytellerUtils.findClosestAttribute(this, 'data-block-id'),
          componentIndex: StorytellerUtils.findClosestAttribute(this, 'data-component-index')
        });
        break;

      default:
        break;
    }
  });
};

/**
 * If I understand this correctly, this sets the height of all iframes in a block to the height of the
 * tallest component in that block. A short text block next to a tall viz will stretch to match the viz.
 * I think the only purpose is to make the blank space under the editor clickable/enter editing the text.
 *
 * TODO: See if this has any other uses, and if not, remove it.
 */
export const updateEditorHeights = (blockId: string, $blockElement: JQuery<HTMLElement>) => {
  let contentHeight = 0;
  let maxEditorHeight = 0;
  const componentData: BlockComponent[] = storyStore.getBlockComponents(blockId);
  const $iframes = $blockElement.find('.component-html iframe');
  const isTextTopMediaBlock = $blockElement.find('.text-top-media').length > 0;
  const iframeContentMissing = ($iframes.toArray() as HTMLIFrameElement[]).some(contentMissingCheck);

  if (isTextTopMediaBlock) {
    $iframes.each(setHeightToContentHeight);
  } else {
    componentData.forEach((componentDatum, i) => {
      const $component = $blockElement.find('.component-container').eq(i);
      const $componentBase = $blockElement.find('.component-base-container').eq(i);
      const isCol12 = shouldUseReactComponentBase()
        ? $componentBase.hasClass('col12')
        : $component.hasClass('col12');

      if (StorytellerUtils.componentInstanceOf(componentDatum.type, 'html')) {
        contentHeight = getHTMLComponentHeight($component);
      } else if (componentDatum.type === 'hero') {
        contentHeight = getHeroComponentHeight($blockElement.find('.component-hero'));
      } else if (!isCol12) {
        contentHeight = getGenericComponentHeight($component);
      }

      maxEditorHeight = Math.max(maxEditorHeight, contentHeight);
    });

    // If the iframe hasn't rendered, we'll fall on the phantom editor
    // with the greatest height. Phantom editors are generated in componentHtml.
    if (iframeContentMissing) {
      const $elements = $blockElement.find('div.typeset');

      maxEditorHeight = _.chain($elements)
        .map($)
        .invokeMap('outerHeight', true)
        .concat(maxEditorHeight)
        .max()
        .value();
    }

    $blockElement.find('.component-container > .component-html > iframe').height(maxEditorHeight);
  }
};

const getHTMLComponentHeight = ($component: JQuery<HTMLElement>): number => {
  const editor = richTextEditorManager.getEditor($component.children(':first').attr('data-editor-id'));

  return editor ? editor.getContentHeight() : 0;
};

const getHeroComponentHeight = ($component: JQuery<HTMLElement>): number => {
  const heroHeight = $component.height() as number;
  const editor = richTextEditorManager.getEditor($component.attr('data-editor-id'));

  return editor ? Math.max(editor.getContentHeight(), heroHeight) : heroHeight;
};

const getGenericComponentHeight = ($component: JQuery<HTMLElement>): number => {
  return ($component.outerHeight(true) as number) - 1;
};

const setHeightToContentHeight = (key: number, value: HTMLElement): void => {
  const editor = richTextEditorManager.getEditor($(value).parent('[data-editor-id]').attr('data-editor-id'));

  if (editor) {
    $(value).height(editor.getContentHeight());
  }
};

const contentMissingCheck = (iframe: HTMLIFrameElement): boolean => {
  const contentDocument = iframe.contentDocument;
  // If we have a height, then we have a loaded Squire instance.
  return (
    !contentDocument || (contentDocument && contentDocument.body && contentDocument.body.clientHeight === 0)
  );
};

/**
 * Setup to run a jQuery plugin on a div. Originally used by `runStoryComponentRenderer`,
 * and adapted for use with React.
 *
 * React and jQuery are not able to render on the same div nicely. As of writing:
 *  - React controls `<div class="component-container">`
 *  - JQuery controls `<div class="component">`
 * React is able to render the component-container div, but needs some setup before the jQuery plugin
 * can take over on the component div, especially when changing plugins entirely.
 */
export const runJQueryPlugin = (
  componentContainer: HTMLElement,
  needToChangeRenderer: boolean,
  jQueryRendererToRun: string,
  pluginProps: any
) => {
  const $componentContainer = $(componentContainer);

  // Remove all children except the first one, and return the first one.
  // The first one _should_ be the component div, the rest are componentEditControls, if they exist.
  let $componentDiv = $componentContainer.children().eq(0);

  if (needToChangeRenderer) {
    // Wipe out the old component and create a new one.
    $componentDiv = $('<div>', { class: 'component' });

    // This was copied from the original componentBaseJquery
    $componentDiv.toggleClass('editing', pluginProps.editMode);

    // Trigger the destroy event on the old component. I think this is forwarded to the viz library?
    $componentContainer.find('.component').trigger('destroy');
    // Empty the container entirely and append the new component.
    $componentContainer.empty().append($componentDiv);
  }

  // Run the jQuery plugin with the given props.
  $componentDiv[jQueryRendererToRun](pluginProps);
};

/**
 * Helper to trigger the 'SOCRATA_VISUALIZATION_INVALIDATE_SIZE' event on a component.
 * Originally copied from `withLayoutHeightFromComponentData` jQuery plugin.
 * Much of this is very refactorable, depending on the state of jQuery -> React and `story-viz-react`.
 */
export const socrataVizInvalidateSizeHelper = (componentContainer: HTMLElement) => {
  const $componentContainer = $(componentContainer);
  const $componentDiv = $componentContainer.find('.component');
  if ($componentDiv.length === 0) return;

  // CBR-TODO: I copied this but I don't know what it does or if it works
  if (
    $componentDiv.closest('.layout-expanded').length > 0 &&
    $componentDiv.closest('.block .fluid6').length
  ) {
    return; // Ignore all height settings in embed6 blocks with expanded layout.
  }

  const renderedVif = $componentDiv[0].getAttribute('data-rendered-vif');
  const $componentContent = $componentDiv.find('.component-content');
  // StoryVisualizations and MeasureCharts handle data-rendered-vif in React
  const renderedVifNotNeeded =
    $componentContent.hasClass('story-viz-react') || $componentDiv.hasClass('component-measure-chart');

  if (renderedVif !== null || renderedVifNotNeeded) {
    // Normal case, use the standard invalidate size API used by visualizations on the correct div.
    // If there's a `common-viz-react` div, trigger the event on that instead.
    const commonVizReact = $componentDiv.find('.common-viz-react');
    if (commonVizReact.length > 0) {
      commonVizReact.triggerHandler('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    }
    $componentContent.triggerHandler('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
  } else {
    // If there is no `data-rendered-vif` attribute on the element,
    // then we should use the 'invalidateSize' handler.
    $componentContent.triggerHandler('invalidateSize');
  }
};
