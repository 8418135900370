// Copies select properties off the window object as an encapsulated environment.

import InteractiveUser from 'common/types/users/interactiveUser';
import { View } from 'common/types/view';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { StoryData, StoryViewRights } from 'types';

const envProperties = [
  'AIRBRAKE',
  'APPROVALS_GUIDANCE',
  'CAN_MANAGE_STORY_VISIBILITY',
  'CORE_SERVICE_APP_TOKEN',
  'CORE_VIEW',
  'CURRENT_USER',
  'CURRENT_USER_STORY_AUTHORIZATION',
  'CUSTOM_THEMES',
  'CUSTOM_THEME_LAYOUT_MAP',
  'DEFAULT_THEMES',
  'DOWNTIMES',
  'EDIT_MODE',
  'EMBED_CODE_SANDBOX_IFRAME_ALLOWANCES',
  'ENVIRONMENT',
  'IMAGES',
  'IS_STORY_PUBLISHED',
  'PERSONALIZED_STORY_DATA',
  'PRIMARY_OWNER_UID',
  'PUBLISHED_STORY_DATA',
  'RELATIVE_URL_ROOT',
  'RENDER_ASSET_ACTION_BAR',
  'STORY_DATA',
  'STORY_UID',
  'STORY_PREVIEW_URL',
  'STORY_VIEW_URL',
  'STORY_EDIT_URL',
  'THEMES_ASSET_PATH',
  'TRANSLATIONS',
  'RENDER_EMBEDDED_SCHEDULE_SEND',
  'RENDER_PDF_CREATED_TIMESTAMP',
  'RENDER_EMBEDDED_PERSONALIZED_RESET'
];

export interface StorytellerFontTheme {
  description: string;
  id: string | number;
  css_variables: {
    '$body-font-stack': string;
    '$heading-font-stack': string;
    '$default-type-color': string;
    '$heading-type-color': string;
    [key: string]: string;
  };
  title: string;
}

export interface StorytellerCustomFontTheme extends StorytellerFontTheme {
  updated_at: number;
  domain_cname: string;
  persisted: boolean;
  class_name: string;
}

export interface Environment {
  AIRBRAKE?: object;
  APPROVALS_GUIDANCE?: GuidanceSummaryV2;
  CAN_MANAGE_STORY_VISIBILITY?: boolean;
  CORE_SERVICE_APP_TOKEN?: string;
  CORE_VIEW?: View;
  CURRENT_USER?: InteractiveUser;
  CURRENT_USER_STORY_AUTHORIZATION?: {
    viewRights: StoryViewRights;
  };
  CUSTOM_THEMES?: StorytellerCustomFontTheme[];
  CUSTOM_THEME_LAYOUT_MAP?: object;
  DEFAULT_THEMES?: { themes: StorytellerFontTheme[] };
  DOWNTIMES?: object;
  EDIT_MODE?: boolean;
  EMBED_CODE_SANDBOX_IFRAME_ALLOWANCES?: object;
  ENVIRONMENT?: 'development' | 'test' | 'production';
  IMAGES?: {
    COVER_IMAGE_ICON: string;
  };
  IS_STORY_PUBLISHED?: boolean;
  PERSONALIZED_STORY_DATA?: StoryData;
  PRIMARY_OWNER_UID?: string;
  PUBLISHED_STORY_DATA?: StoryData;
  RELATIVE_URL_ROOT?: string;
  RENDER_ASSET_ACTION_BAR?: boolean;
  STORY_DATA?: StoryData;
  STORY_UID?: string;
  STORY_PREVIEW_URL?: string;
  STORY_VIEW_URL?: string;
  STORY_EDIT_URL?: string;
  THEMES_ASSET_PATH?: string;
  TRANSLATIONS?: object;
  RENDER_EMBEDDED_SCHEDULE_SEND?: boolean;
  RENDER_PDF_CREATED_TIMESTAMP?: boolean;
  RENDER_EMBEDDED_PERSONALIZED_RESET?: boolean;
}

const environment: Environment = Object.fromEntries(
  envProperties.map((prop) => [prop, (window as any)[prop]])
);

export default environment;
